import React, { useState } from 'react';
import './BookNow.css';

const BookNow = () => {
  // Example states for the form options
  const [duration, setDuration] = useState('1-3-days');
  const [additionalFeed, setAdditionalFeed] = useState('1-add-feed');
  const [guests, setGuests] = useState('100-guests');

  // Example placeholders for product data
  const productTitle = 'Custom Private Event Package';
  const productPrice = 199.00;
  // const starRating = 4.9;
  // const reviewCount = 77;

  const handleAddToCart = () => {
    alert('Added to cart with options:\n' +
      `Duration: ${duration}\n` +
      `Additional Feed: ${additionalFeed}\n` +
      `Guests: ${guests}\n`
    );
  };

  const handleBuyNow = () => {
    alert('Proceeding to checkout!');
  };

  return (
    <main className="product-page">
      {/* Left section: product/gallery */}
      <section className="product-gallery">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/vip-feed-d30d0.appspot.com/o/FCMImages%2FIMG_6250.png?alt=media&token=1d799af7-dd8b-43ff-a500-8fad0de6f12c" 
          alt="Example Product"
          className="main-product-image"
        />
      </section>

      {/* Right section: product details */}
      <section className="product-details">
        <h1 className="product-title">{productTitle}</h1>

        {/* Price & Rating */}
        <div className="price-rating">
          <div className="product-price">Starting at ${productPrice.toFixed(2)}</div>
          {/* <div className="star-rating">
            <span>{'⭐️'.repeat(Math.round(starRating))}</span>
            <span className="review-count">({reviewCount} Reviews)</span>
          </div> */}
          {/* Example: PayPal line or monthly installment note could go here */}
        </div>

        {/* Product Options Form */}
        <form className="product-options">
          {/* Event Duration */}
          <label htmlFor="event-duration">Event Duration</label>
          <select
            id="event-duration"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          >
            <option value="1-3-days">1-7 Days</option>
            <option value="4-7-days">7-14 Days</option>
          </select>

          {/* Additional Feed */}
          <label htmlFor="additional-feed">Additional Feeds</label>
          <select
            id="additional-feed"
            value={additionalFeed}
            onChange={(e) => setAdditionalFeed(e.target.value)}
          >
            <option value="no-add-feed">No Additional Feed</option>
            <option value="1-add-feed">1 Additional Feed</option>
            <option value="2-add-feed">2 Additional Feeds</option>
            <option value="3-add-feed">3 Additional Feeds</option>
          </select>

          {/* Number of Guests */}
          <label htmlFor="guests">Number of Guests</label>
          <select
            id="guests"
            value={guests}
            onChange={(e) => setGuests(e.target.value)}
          >
            <option value="50-guests">50+ Guests</option>
            <option value="100-guests">100+ Guests</option>
            <option value="200-guests">200+ Guests</option>
            <option value="300-guests">300+ Guests</option>
          </select>
        </form>

        {/* Action Buttons */}
        <div className="action-buttons">
          <button className="btn add-to-cart" onClick={handleAddToCart}>
            Request Info
          </button>
          <button className="btn buy-now" onClick={handleBuyNow}>
            Buy Now
          </button>
        </div>

        {/* What's Included */}
        <div className="included-features">
          <h3>What comes with the package?</h3>
          <ul>
            <li>A private event platform for you and your guests</li>
            <li>Unlimited photo, video and voice uploads</li>
            <li>Scheduled prompts, event updates and reminders sent through SMS</li>
            <li>Host access to our Event Hub</li>
            <li>A custom QR code for your feed</li>
            <li>A photo album of all your guests’ memories</li>
          </ul>
        </div>
      </section>
    </main>
  );
};

export default BookNow;
