import React, { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
// import ReservationsPage from './ReservationsPage';
import BookNow from './BookNow';
import AboutPage from './AboutPage';
import FAQPage from './FAQPage';
import Container from './Container';
import HeroSection from './HeroSection';
import MoreInfo from './MoreInfo';
import './PolaroidGallery.css';
import useScrollAnimation from './useScrollAnimation';

function App() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [polies, setPolaroids] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash) {
      const section = document.getElementById(location.hash.substring(1)); // Remove the "#" from the hash
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  useScrollAnimation();

  const handleLogoClick = () => {
    if (location.pathname === "/") {
      // Clear hash and query parameters
      navigate("/", { replace: true }); // Reset the URL to just "/"
      window.history.pushState({}, document.title, "/"); // Ensure the URL is clean
      window.scrollTo({ top: 0, behavior: "smooth" }); // Smooth scroll to the top
      window.location.reload()
    } else {
      navigate("/");
      window.location.reload()
    }
  };

  const prompts = [
    "Welcome to Snappo! Stay updated with event details here.",
    "Happy travels! Document your trip here.",
    "Who made it to Italy? Show us what you've been up to!",
    "Who's out sightseeing? Send us a pic in front of a historic landmark",
  ];

  const captions = [
    "Happy travels! Document your trip here.",
    "Who made it to Italy? Show us what you've been up to!",
    "Boat Day is finally here! Show us what your crew is up to",
    "Who's out sightseeing? Send us a pic in front of a historic landmark",
    "The bride and groom have arrived! Snap a pic with your date",
    "Boat Day is finally here! Show us what your crew is up to",
    "The bride and groom have arrived! Snap a pic with your date",
    "Happy travels! Document your trip here."
  ];

  useEffect(() => {
    const fetchImageUrls = async () => {
      const imagePaths = [
        "FCMImages/IMG_1.jpg",
        "FCMImages/IMG_2.jpg",
        "FCMImages/IMG_3.jpg",
        "FCMImages/IMG_4.jpg",
        "FCMImages/IMG_5.jpg",
        "FCMImages/IMG_6.jpg",
        "FCMImages/IMG_7.jpg",
        "FCMImages/IMG_8.jpg",
        "FCMImages/IMG_9.jpg",
        "FCMImages/IMG_10.jpg",
      ];

      const polaroids = [
        "FCMImages/IMG_11.jpeg",
        "FCMImages/IMG_12.jpeg",
        "FCMImages/IMG_14.jpeg",
        "FCMImages/IMG_15.jpg",
        "FCMImages/IMG_16.jpg",
        "FCMImages/IMG_17.JPG",
        // "FCMImages/IMG_18.jpg",
        // "FCMImages/IMG_19.jpeg",
      ];

      try {
        const urls = await Promise.all(
          imagePaths.map(async (path) => {
            const imageRef = ref(getStorage(), path);
            return await getDownloadURL(imageRef);
          })
        );
        const polaroidUrls = await Promise.all(
          polaroids.map(async (path) => {
            const imageRef = ref(getStorage(), path);
            return await getDownloadURL(imageRef);
          })
        );

        setPolaroids(polaroidUrls);
        setImageUrls(urls);
      } catch (error) {
        console.error("Error fetching image URLs:", error);
      }
    };
    fetchImageUrls();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h1>
          <Link to="/" onClick={handleLogoClick}>
            {/* <img src='/logo_snappo.png' alt='snappo' className="logo-image" ></img> */}
            snappo
          </Link>
        </h1>

        {/* New wrapper for horizontal nav links (visible on desktop) */}
        <div className="nav-wrap">
          <ul className="nav-links">
            <li><a href="/#features">Features</a></li>
            <li><Link to="/#faq">FAQ</Link></li>
            <li><Link to="/eventhub">Event Hub</Link></li>
            <li><Link to="/reservations">Book Now</Link></li>
          </ul>
        </div>

        <nav>
          <button className="menu-button" onClick={toggleDropdown}>
            <span className="material-icons">menu</span>
          </button>
          <ul className={`header-dropdown-menu ${isDropdownOpen ? "show" : ""}`}>
          <li>
              <Link to="/reservations" onClick={() => setIsDropdownOpen(false)}>
                Book Now
              </Link>
            </li>
            <li>
              <Link to="/eventhub" onClick={() => setIsDropdownOpen(false)}>
                Event Hub
              </Link>
            </li>
            <li>
              <Link to="/#features" onClick={() => setIsDropdownOpen(false)}>
                Features
              </Link>
            </li>
            <li>
              <Link to="/#faq" onClick={() => setIsDropdownOpen(false)}>
                FAQ
              </Link>
            </li>
          </ul>
        </nav>
      </header>

      <main>
      <Routes>
        <Route 
          path="/" 
          element={
            <Container>
              <HeroSection
                images ={imageUrls}
                title={
                  <>
                  A platform curated for<br /> 
                  your private event.
                  </>
                }
                ctaText="Book Now"
                ctaLink="/reservations"
              />
              
              <div className="communication-tools">
                
                <div className="text-section">
                  <h2>CONNECT. SHARE. REMEMBER.</h2>
                  <p>The Ultimate</p>
                  <p>Private Event Platform</p>
                </div> 

                <div className="image-section">
                  <img 
                    className="phone-image"
                    src="https://firebasestorage.googleapis.com/v0/b/vip-feed-d30d0.appspot.com/o/FCMImages%2FIMG_5982-removebg-preview.png?alt=media&token=824768f7-994b-4a46-83fb-b882e58308f5" 
                    alt="Custom Social Feed on iPhone"
                  />
                  <img 
                    className="phone-image"
                    src="https://firebasestorage.googleapis.com/v0/b/vip-feed-d30d0.appspot.com/o/FCMImages%2FIMG_5982-removebg-preview.png?alt=media&token=824768f7-994b-4a46-83fb-b882e58308f5" 
                    alt="Custom Social Feed on iPhone"
                  />
                </div>
              </div>
              <section id="features">
                <div className="communication-tools-brokenout">
                  <div className="text-section">
                    <h2>CONNECT.</h2>
                    <p>A platform to connect with your guests</p>
                    <p className="p2">Curated prompts, event updates and reminders sent directly to your guests through SMS.</p>
                  </div>
                  <div className="image-section">
                    <div className="prompt-gallery">
                      {prompts.map((prompt, index) => (
                        <div className="prompt" key={index}>
                          <div className="prompt-caption">{prompt}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </section>
              <div className="communication-tools-brokenout-reverse">
                <div className="text-section">
                  <h2>SHARE.</h2>
                  <p>A platform to capture every perspective</p>
                  <p className="p2">Photo, video and audio uploads are expedited through quick capture.</p>
                </div>
                <div className="image-section">
                  <div className={"polaroid-gallery"}>
                    {polies.map((url, index) => (
                      <div
                        className="polaroid"
                        key={index}
                        style={{ '--rotation': `${Math.random() * 20 - 10}deg` }}
                      >
                        <img src={url} alt={`Polaroid ${index}`} />
                        <div className="polaroid-caption">{captions[index]}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="communication-tools-2"
                style={{
                  backgroundImage: `url(${polies[1]})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center"
                }}
              >
                <div className="text-section">
                  <h2>Remember.</h2>
                  <p>A platform to create digital memories</p>
                  <p className="p2">Digital or hard cover photo album to cherish the memories.</p>
                </div>
              </div>

              <div className='feature-wrap'>
                <div className='feature-content'>
                  <div className='feature-icon'>
                    <span className="material-icons-outlined">
                      chat
                    </span>                  
                  </div>
                  <div className='feature-text'>
                    Scheduled Prompts
                  </div>
                  <div className='feature-paragraph'>
                    Engage and connect with your guests throughout the entire event.
                  </div>
                </div>

                <div className='feature-content'>
                  <div className='feature-icon'>
                    <span className="material-icons-outlined">
                      event
                    </span>                  
                  </div>
                  <div className='feature-text'>
                    Event Details
                  </div>
                  <div className='feature-paragraph'>
                    Itinerary, locations, and contacts all in one platform.
                  </div>
                </div>

                <div className='feature-content'>
                  <div className='feature-icon'>
                    <span className="material-icons-outlined">
                      signal_cellular_alt
                    </span>                
                  </div>
                  <div className='feature-text'>
                    No Download Required
                  </div>
                  <div className='feature-paragraph'>
                    Links are sent through SMS for quick access.
                  </div>
                </div>
              </div>

              {/* FAQ Content */}
              <section id='faq'>
                <FAQPage/>
              </section>
              
              <div className='closing-section'>
                <div className='closing-wrap'>
                  <h2>Ready to book your event?</h2>
                  <div className='button-wrap'>
                    <button className='book-now-button'>
                      <Link to="/reservations">Book Now</Link>
                    </button>
                    <button className="outline-button">
                      <Link to="/faq">Get in Touch</Link>
                    </button>                  
                  </div>
                </div>
              </div>

            </Container>
          } 
        />
        <Route path="/reservations" element={<Container><BookNow /></Container>} />
        <Route path="/eventhub" element={<Container><MoreInfo /></Container>} />
        <Route path="/about" element={<Container><AboutPage /></Container>} />
        <Route path="/faq" element={<Container><FAQPage /></Container>} />
      </Routes>
      </main>

      <footer>
        {/* <div className='footer-wrap'>
          <button className='footer-button'>
            <h1>Contact Us</h1>
            <p>info@snappo.io</p>
          </button>

          <button className='footer-button'>

          </button>

          <button className='footer-button'>

          </button>
        </div> */}

        <p>&copy; 2024 Snappo. All rights reserved.</p>

      </footer>
    </div>
  );
}

// const storage = getStorage();

// function HomePage( {imageUrls} ) {

//   // const prompts = [
//   //   "Snap a pic!",
//   //   "Send us a pic of your travels",
//   //   "How well do you know the bride or groom? Click here to take a quiz.",
//   //   "Welcome to Snappo! Stay updated with event details here.",
//   //   "The ceremony is about to begin, don't forget to silence your phones."
//   // ];

//   // const reviews = [
//   //   "This app made my event so memorable!",
//   //   "The photo-sharing feature is fantastic.",
//   //   "Loved the personalized event hub. Highly recommend!",
//   //   "I love how connected we felt the entire week!",
//   //   "The photo-sharing feature is fantastic.",
//   // ];

//   return (
//     <div className="homepage">
//       {/* <h1>The Ultimate</h1>
//       <h2>Private Social Platform</h2>
//       <h3>Connect with your guests through a platform curated for your private event.</h3>
//       <div className="smooth-carousel">
//         <div className="carousel-track">
//           {[...imageUrls, ...imageUrls, ...imageUrls, ...imageUrls].map((url, index) => (
//             <div key={index} className="carousel-itm">
//               <img src={url} alt={`Event ${index + 1}`} />
//             </div>
//           ))}
//         </div>
//       </div> */}

//       {/* <div className="smooth-carousel reviews-carousel">
//         <div className="carousel-track">
//           {[...prompts, ...prompts, ...prompts].map((prompt, index) => (
//             <div key={index} className="prompt-slide">
//               <p className="prompt-text">"{prompt}"</p>
//             </div>
//           ))}
//         </div>
//       </div> */}

//       {/* <div className="card-features">
//         <Card title="Connect" 
//           description="Send mass texts and keep your guests engaged seamlessly.">
//         </Card>
//         <Card title="Share"
//           description="Personalized social feeds to share moments in real-time.">
//         </Card>
//         <Card title="Remember"
//           description="Receive a digital and physical photo album of your event memories.">
//         </Card>
//       </div> */}


      

//       {/* <div className="communication-tools">
//         <div className="image-section">
//           <img 
//             className="phone-image"
//             src="https://firebasestorage.googleapis.com/v0/b/vip-feed-d30d0.appspot.com/o/FCMImages%2FIMG_5982-removebg-preview.png?alt=media&token=824768f7-994b-4a46-83fb-b882e58308f5" 
//             alt="Custom Social Feed on iPhone"
//           />
//         </div>
//         <div className="text-section">
//           <h2>Share</h2>
//           <p>
//             A platform to capture every perspective.
//           </p>
//         </div>
//       </div>

//       <div className="communication-tools">
//         <div className="text-section">
//           <h2>Remember</h2>
//           <p>
//             A platform to capture every perspective.
//           </p>
//         </div>
//       </div> */}

//       {/* <div className="smooth-carousel reviews-carousel">
//         <div className="carousel-track">
//           {[...reviews, ...reviews, ...reviews].map((review, index) => (
//             <div key={index} className="review-slide">
//               <p className="review-text">"{review}"</p>
//             </div>
//           ))}
//         </div>
//       </div> */}

//     </div>

    
//   );
// }

export default App;