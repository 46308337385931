import React, { useState } from 'react';
import './FAQPage.css';

const FAQPage = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const faqData = [
    {
      question: 'Who can see my private event feed?',
      answer: 'Only guests who are provided a unique link or QR code can access your feed.',
    },
    {
      question: 'How do I upload my guest list?',
      answer:
        'Hosts have the ability to access the Event Hub, where they can upload and edit the guest list along with the event details.',
    },
    {
      question: 'How do guests join my event?',
      answer:
        'Guests can join directly from a link or QR Code.',
    },
    {
      question: 'Do I need internet access?',
      answer: 'Yes, either WiFi or cellular data is required.',
    },
    {
      question: 'Does Snappo send text messages worldwide?',
      answer: 'Yes, we can send SMS worldwide.',
    },
    {
      question: 'How can I save the photos?',
      answer: 'Hosts will recieve full resolution media after the event is over. Guests can download individual photos or add them to a collection to save after the event is over.',
    },
  ];

  return (
    <div className="faq-wrap">
      {/* <h1>FAQ</h1> */}
      <div className="faq-grid">
        {faqData.map((item, index) => (
          <div
            className={`faq-card ${openFAQ === index ? 'open' : ''}`}
            key={index}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-header">
              <span className="faq-question">{item.question}</span>
              <span className="faq-toggle">
                {openFAQ === index ? '▲' : '▼'}
              </span>
            </div>
            {openFAQ === index && (
              <div className="faq-answer">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQPage;
