import { useEffect } from 'react';

function useScrollAnimation() {
  useEffect(() => {
    const sections = document.querySelectorAll('.text-section, .polaroid-gallery, .hero-content, .closing-wrap');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    }, { threshold: 0.4 });     // Adjust threshold as needed

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);
}

export default useScrollAnimation;
