import React, { useState, useEffect } from 'react';
import './HeroSection.css';

const HeroSection = ({ images, title, subtitle, ctaText, ctaLink }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (images.length > 0) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 4000);

      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [images]);

  return (
    <div className="hero-section">
      {images.length > 0 && (
        <img
          src={images[currentImageIndex]}
          alt="Hero Background"
          className="hero-background-image"
        />
      )}
      <div className="hero-content">
        <h1>{title}</h1>
        <p>{subtitle}</p>
        {ctaText && ctaLink && (
          <a href={ctaLink} className="hero-cta">
            {ctaText}
          </a>
        )}
      </div>
    </div>
  );
};

export default HeroSection;
