import React, { useState, useEffect } from 'react';
import { firestore, doc, getDoc, setDoc, auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, onAuthStateChanged, updateProfile } from './firebase-config';
// import { signOut } from 'firebase/auth'; // Import signOut from firebase/auth
import './SignUpForm.css'; // Adjust the path as necessary

const SignUpForm = ({ eventCode, onSignUpSuccess }) => {
  const [isSignUp, setIsSignUp] = useState(true);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState(''); // Add name state
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    try {
      let userCredential;
  
      if (isSignUp) {
        // Sign up a new user
        userCredential = await createUserWithEmailAndPassword(auth, email, password);  
        await updateProfile(userCredential.user, { displayName: name });
      } else if (isPasswordReset) {
        // Handle password reset
        await sendPasswordResetEmail(auth, resetEmail);
        console.log('Password reset email sent');
        setSuccessMessage('Password reset email sent!');
        setResetEmail('');
        setIsPasswordReset(false);
        return;
      } else {
        // Sign in an existing user
        userCredential = await signInWithEmailAndPassword(auth, email, password);
        console.log('User signed in:', userCredential.user.uid);
      }

      // Check if the user is already added to the event's profiles collection
      const userDocRef = doc(firestore, `events/${eventCode}/profiles`, user.uid);
      const userDocSnapshot = await getDoc(userDocRef);
  
      if (!userDocSnapshot.exists()) {
        // Add user to the profiles collection for this event
        await setDoc(userDocRef, {
          displayName: userCredential.user.displayName || name,
          photoURL: userCredential.user.photoURL || '',
          userId: userCredential.user.uid,
        });
        console.log(`User added to profiles for event: ${eventCode}`);
      } else {
        console.log(`User already exists in profiles for event: ${eventCode}`);
      }
  
      if (onSignUpSuccess) {
        onSignUpSuccess(user);
      }
      
      console.log(isSignUp ? 'Sign-up successful' : 'Sign-in successful:', user);
      setSuccessMessage(isSignUp ? 'Sign-up successful!' : 'Sign-in successful!');
      setEmail('');
      setPassword('');
    } catch (error) {
      setError(error.message);
    }
  };  

  // const handleSignOut = async () => {
  //   await signOut(auth);
  //   console.log('Sign-out successful');
  //   setSuccessMessage('Sign-out successful!');
  // };

  return (
    <div className="sign-up-form-container">
        <div>
          {isPasswordReset ? (
            <div>
              <h2>Reset Password</h2>
              {error && <p>{error}</p>}
              {successMessage && <p>{successMessage}</p>}
              <form onSubmit={handleSubmit} className="sign-up-form">
                <input 
                  type="email" 
                  placeholder="Enter your email" 
                  value={resetEmail} 
                  onChange={(e) => setResetEmail(e.target.value)} 
                  required 
                />
                <button type="submit">Reset Password</button>
              </form>
              <div className="back-to-signin-container">
                <button onClick={() => setIsPasswordReset(false)}>Back to Sign In</button>
              </div>
            </div>
          ) : (
            <div>
              <h2>{isSignUp ? 'Sign Up' : 'Sign In'}</h2>
              {error && <p>{error}</p>}
              {successMessage && <p>{successMessage}</p>}
              <form onSubmit={handleSubmit} className="sign-up-form">
                {isSignUp && (
                  <input 
                    type="text" 
                    placeholder="Name" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                    required 
                  />
                )}
                <input 
                  type="email" 
                  placeholder="Email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  required 
                />
                <input 
                  type="password" 
                  placeholder="Password" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                  required 
                />
                <button type="submit">{isSignUp ? 'Sign Up' : 'Sign In'}</button>
              </form>
              <div className="sign-up-button-container">
                <button onClick={() => setIsSignUp(!isSignUp)}>
                  {isSignUp ? 'Sign In' : 'Sign Up'}
                </button>
                <button onClick={() => setIsPasswordReset(true)}>
                  Forgot Password?
                </button>
              </div>
            </div>
          )}
        </div>
    </div>
  );
};

export default SignUpForm;
