import React, { useRef, useEffect } from "react";
import { QRCodeCanvas } from "qrcode.react";
import "./QRCode.css";

const QRCode = ({ eventCode }) => {
  const eventUrl = `app.snappo.io/${eventCode}`;
  const qrRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (qrRef.current && canvasRef.current) {

      const generateQRImage = () => {
        const qrCanvas = qrRef.current.querySelector("canvas");
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        // Set canvas size
        const qrSize = 200; // QR code size
        const canvasWidth = qrSize + 100;
        const canvasHeight = qrSize + 150; // Add space for text
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        // Draw white background
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Draw Company Name at the Top
        ctx.fillStyle = "black";
        ctx.font = "40px Lobster";
        ctx.textAlign = "center";
        ctx.fillText("snappo", canvas.width / 2, 35); // Text at the top center
        
        // Draw Company Name at the Top
        ctx.fillStyle = "black";
        ctx.font = "16px Gothic A1";
        ctx.textAlign = "center";
        ctx.fillText("Access your event here:", canvas.width / 2, 70);

        // Draw QR Code in the Middle
        const qrX = (canvas.width - qrSize) / 2; // Center horizontally
        const qrY = 85; // Below the company name
        const qrImage = new Image();
        qrImage.src = qrCanvas.toDataURL("image/png");

        qrImage.onload = () => {
          ctx.drawImage(qrImage, qrX, qrY, qrSize, qrSize);

          // Draw Event URL at the Bottom
          ctx.fillStyle = "black";
          ctx.font = "16px Gothic A1";
          ctx.fillText(eventUrl, canvas.width / 2, qrY + qrSize + 30); // Text at the bottom center
        };
      };

    generateQRImage();
  }
}, [eventUrl]);
  

  const handleDownloadQR = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const qrImage = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = qrImage;
      link.download = `event_qr_${eventCode}.png`;
      link.click();
    }
  };

  return (
    <div className="qr-section">
      <div className="qr-page">
        {/* <h2>Event QR Code</h2> */}
        {/* <p>Scan this QR code to access your event:</p> */}

        {/* Hidden QR Code for extraction */}
        <div ref={qrRef} style={{ display: "none" }}>
          <QRCodeCanvas value={eventUrl} size={300} />
        </div>

        {/* Canvas with QR Code, Company Name, and URL */}
        <canvas ref={canvasRef}></canvas>

        <button onClick={handleDownloadQR}>Download QR Code</button>
      </div>
    </div>
  );
};

export default QRCode;
