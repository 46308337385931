import React, { useState, useEffect, useCallback } from 'react';
import { firestore, collection, addDoc, doc, getDocs, deleteDoc } from './firebase-config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Prompts.css';

function Prompts({ eventCode }) {
  const [promptDescription, setPromptDescription] = useState('');
  const [scheduledDate, setScheduledDate] = useState('');
  const [error, setError] = useState('');
  const [promptsuccessMessage, setPromptSuccessMessage] = useState('');
  const [prompts, setPrompts] = useState([]);
  const [filterDate, setFilterDate] = useState(null);

  // Fetch scheduled prompts from Firestore
  const fetchPrompts = useCallback(async () => {
    try {
      const promptsPath = `events/${eventCode}/prompts`;
      const querySnapshot = await getDocs(collection(firestore, promptsPath));
      const promptList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedPrompts = promptList.sort((a, b) => {
      const dateA = a.timestamp?.toDate ? a.timestamp.toDate() : new Date();
      const dateB = b.timestamp?.toDate ? b.timestamp.toDate() : new Date();
      return dateA - dateB; // Ascending order
    });

      setPrompts(sortedPrompts);
    } catch (error) {
      console.error('Error fetching prompts:', error);
    }
  }, [eventCode]);

  // Handle form submission for prompts
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!promptDescription || !scheduledDate) {
      setError('Please fill in all fields.');
      return;
    }

    setError('');
    setPromptSuccessMessage('');

    try {
      const promptsPath = `events/${eventCode}/prompts`;
      await addDoc(collection(firestore, promptsPath), {
        promptDescription: promptDescription,
        timestamp: new Date(scheduledDate),
        status: 'Pending',
      });

      setPromptSuccessMessage('Prompt scheduled successfully!');
      setPromptDescription('');
      setScheduledDate('');
      fetchPrompts();
    } catch (error) {
      console.error('Error adding prompt:', error);
      setError('Error adding prompt. Please try again.');
    }
  };

  // Filter prompts by selected date
  const filteredPrompts = prompts.filter((prompt) => {
    if (!filterDate) return true; // Show all if no filter date is selected
    const promptDate = new Date(prompt.timestamp.seconds * 1000);
    return (
      promptDate.getFullYear() === filterDate.getFullYear() &&
      promptDate.getMonth() === filterDate.getMonth() &&
      promptDate.getDate() === filterDate.getDate()
    );
  });

  useEffect(() => {
    fetchPrompts();
  }, [fetchPrompts]);

  // Function to delete a specific prompt by id
const handleDeletePrompt = async (promptId, e) => {
  e.preventDefault();

  try {
    // Reference the specific prompt document
    const promptDocRef = doc(firestore, `events/${eventCode}/prompts`, promptId);
    await deleteDoc(promptDocRef);
    console.log(`Prompt with ID ${promptId} deleted successfully.`);
    fetchPrompts();
    setPromptSuccessMessage('Prompt deleted successfully!');
  } catch (error) {
    console.error("Error deleting prompt:", error);
  }
};

  return (
    <div className="prompts-page">
      <form onSubmit={handleSubmit}>
        <div className='prompt-upload'>
          <h2>Schedule New Prompt</h2>
          <div className="form-group">
            <textarea
              id="promptDescription"
              value={promptDescription}
              onChange={(e) => setPromptDescription(e.target.value)}
              placeholder="Enter prompt description"
              rows="10"
              className="wide-textarea"
            ></textarea>

            <DatePicker
              selected={scheduledDate}
              onChange={(date) => setScheduledDate(date)}
              showTimeSelect
              dateFormat="Pp"
              placeholderText="Select Date"
              className="custom-datepicker"
            />
          </div>
          <button type="submit">Submit</button>
          {error && <p className="error-message">{error}</p>}
          {promptsuccessMessage && <p className="success-message">{promptsuccessMessage}</p>}
        </div>
        <table className="prompts-table">
          <thead>
            <tr>
              <th>Prompt</th>
              <th className="narrower-column">Date
                <div className="date-filter">
                  <DatePicker
                    selected={filterDate}
                    onChange={(date) => setFilterDate(date)}
                    placeholderText="Filter"
                    dateFormat="Pp"
                    className="date-filter-input"
                  />
                </div>
              </th>
              <th className="narrowish-column"> Status</th>
              <th className="narrow-column"></th>
            </tr>
          </thead>
          <tbody>
            {filteredPrompts.map((prompt) => (
              <tr key={prompt.id}>
                <td>{prompt.promptDescription}</td>
                <td className="narrower-column">{new Date(prompt.timestamp.seconds * 1000).toLocaleString()}</td>
                <td className="narrowish-column">{prompt.status}</td>
                <td className="narrow-column">
                  <div className="delete-guest-button-container">
                    <button onClick={(e) => handleDeletePrompt(prompt.id, e)} className="delete-guest-button">
                      <span className="material-icons">delete_outline</span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
    </form>
  </div>
  );
}

export default Prompts;